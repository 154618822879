import LayoutAdmin from '@/layouts/LayoutAdmin'
import myTable from './table'

export default {
  data() {
    return {
      kata_kunci: null,
      pilih_cif: null,
      search: '',
      loadingTb: false,
      pilihVersiSemula: [],
      pilihVersiMenjadi: [],
      pilihSatker:[],
      idxCurrent:0,
      loadingVersi:[],
      isActive: [],
      listBalai: [],
      listProject: [],
      listStatker: [],
      listDataPaket: [],
      proyek:null,
      listVersi: [],
      listsmFilter:[],
      smFilter:null,
      proporsiRegisterIds:[],
      tbData: [],
      validateForm:[v => !!v || 'Tidak Boleh Kosong'],
      dataEdit:{
        id:null,
        keterangan:null,
        pergeseran:null,
        percepatan:null,
        luncuran:null
      }
    }
  },
  created() {
    this.$emit(`update:layout`, LayoutAdmin);
  },
  mounted() {
  
    this.G_GetRef('satker').then(data => {
      this.listStatker = data;
    });

    this.G_GetRef('smFilter').then(data => {
      this.listsmFilter = data;
    });

    this.G_GetRef('proyek').then(data => {
      this.listProject = data;
    });

  },
  components: {
    myTable
  },
  methods: {
    toggleSub(key) {
      this.isActive[key] = !this.isActive[key];
      if (this.isActive[key]) {
        $('#sub_' + key).hide();
      } else {
        $('#sub_' + key).show();

      }
    },
    addSatker(){
      this.idxCurrent++
    },
    removeSatker(){
      this.idxCurrent--
      this.pilihSatker.pop();
      this.pilihVersiSemula.pop();
      this.pilihVersiMenjadi.pop();
      this.listVersi.pop()
    },
    getVersi(idx) {
      
      this.$set(this.loadingVersi, idx, true)
      let filter = [];
      filter.push("kdsatker=" + this.pilihSatker[idx]);

      if (filter.length > 0) {
        filter = "&" + filter.join("&");
      }
      this.G_GetAny("rkakl/versi?saktiEventId=0" + filter).then((res) => {
        
        let resData = res.data;
        let listData = [];
        let arrSub = {};

        resData.forEach((v) => {
          arrSub = {};
          arrSub["text"] = v.saktiVersionNm;
          arrSub["value"] = v.saktiVersionId;
          listData.push(arrSub);
        });

        this.$set(this.listVersi, idx, listData)
        this.$set(this.loadingVersi, idx, false)

      })
    },
    store(){

      let myForm = document.getElementById('formData');
      let formData = new FormData(myForm);

      this.dataEdit.keterangan = formData.get('keterangan')

      this.G_PutAnyCustom('sakti/semula-menjadi/sm-item', this.dataEdit).then(res => {
        
        if (!res.status) {
          // this.getTable();
          this.$snotify.success('Berhasil Mengubah Data');
          this.$refs.modal.close()

          this.loadingTb = true

          let myForm = document.getElementById('formCari');
          let formData = new FormData(myForm);
          var object = {};
          formData.forEach((value, key) => object[key] = value);

          let filter = '';
        
          if (object.satker) {
            if (object.satker != 'all'){
              filter += '&kdsatker='+object.satker
            }
          }
        
          if (this.$refs.form.validate()) {
            this.$refs.btnAct.load(true)
            this.G_GetAny('sakti/semula-menjadi?sVersionId='+this.$refs.versiSemula.getValue()+'&mVersionId='+this.$refs.versiMenjadi.getValue()+''+filter).then(res => {


              if(res.status == 500){
                this.$refs.btnAct.load(false)
                this.loadingTb = false
                
              }else{
                this.listDataPaket = res.data
                this.$refs.btnAct.load(false)
                this.loadingTb = false
              }

            

            })
          }
          
        }else{
          this.$snotify.warning(res.data.message,'Terjadi Kesalahan');
        }

      })
      .finally(() => {
        this.$refs.modal.loadingEnd()
        
      });
    },
    editData(id,keterangan,pergeseran,percepatan,luncuran){

      this.$refs.modal.open("Edit Data", 600)

      this.dataEdit = {
        id:id,
        keterangan:keterangan,
        pergeseran:pergeseran,
        percepatan:percepatan,
        luncuran:luncuran
      }
      
    },
    reGenerate(){
     
      if (this.$refs.form.validate()) {
        let ls = this.pilihSatker
        let sms = []
        Object.keys(ls).forEach(key => {
          console.log(key, ls[key]);
  
          sms.push({
                  "sVersionId":this.pilihVersiSemula[key],
                  "mVersionId":this.pilihVersiMenjadi[key]
              })
        });
  
        let formData = {
          "smFilterId":this.smFilter,
          "sms": sms
        }
        this.generate(formData,"Generate Manual Data")
      }
      
    },
    cariData() {

      $('#tableShow').hide()

      let ls = this.pilihSatker
      let sms = []
      Object.keys(ls).forEach(key => {
        console.log(key, ls[key]);

        sms.push({
                "sVersionId":this.pilihVersiSemula[key],
                "mVersionId":this.pilihVersiMenjadi[key]
            })
      });

      let formData = {
        "smFilterId":this.smFilter,
        "sms": sms,
        "proporsiProyekId":this.proyek
      }

    console.log(formData);
      
      if (this.$refs.form.validate()) {
        this.$refs.btnAct.load(true)
        this.G_PostAnyCustom('sakti/semula-menjadi-gabungan',formData).then(res => {

          if(res.status == 500){
            this.$refs.btnAct.load(false)
            this.loadingTb = false
            $('#tableShow').hide()

            if (res.data.error == 'not_found') {
              this.$refs.notif.warning(res.data.message);
            }
            else{
              this.generate(formData,res.data.message)
            }
          
          }else{
            console.log('res',res);
            this.listDataPaket = res
            this.proporsiRegisterIds = res.proporsiRegisterIds
            $('#tableShow').fadeIn()
            this.$refs.btnAct.load(false)
            this.loadingTb = false
          }
        })
      }

    },
    async generate(formData,title) {
      let judul = "Generate Data"
      let subJudul = title

      console.log('generate',formData);

      if (await this.$refs.confirm.open(judul,subJudul) ) {
          
          this.$refs.confirm.loading()
          this.G_PostAnyCustom('sakti/semula-menjadi-gabungan/generate', formData).then(res => {
           
              if (res) {
                  this.$refs.confirm.close()
                  this.$snotify.success('Silahkan Cari Ulang Data Satker yang di pilih',res.message);
              }else{
                this.$snotify.warning(res.message);
              }
          })
          .finally(() => {
              this.$refs.confirm.loadingEnd()
          }); 
      }

    },
  }
}